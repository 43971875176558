import { MediaType } from '@somostera/tera-database/dist/Medias/Domain/enum/MediaType'
import { Editor } from '@tiptap/react'
import React, { useCallback, useState } from 'react'

import { MenuItem } from '../MenuItem'

import { DropdownMenu } from 'core/components/DropdownMenu'
import { AddLinkModal } from '../modals/AddLinkModal'
import { AddMediaModal } from '../modals/AddMediaModal'
import { AddQuoteBlockModal } from '../modals/AddQuoteBlockModal'
import { SelectMediaModal } from '../modals/SelectMediaModal'

import { FunctionsContainer, MenusContainer } from './styles'

type MediaInfo = {
  url: string
  videoDuration?: number
  fileName?: string
  type?: string
  fileSize?: number
  mimeType?: string
  thumbnail?: string
}

type QuoteBlockInfo = {
  quote: string
  author: string
}

interface MainFunctionsMenuProps {
  editor: Editor
  isDisabled?: boolean
  articleId: string
}

export function MainFunctionsMenu({ articleId, editor, isDisabled = false }: MainFunctionsMenuProps) {
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = React.useState(false)
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false)
  const [isSelectMediaModalOpen, setIsSelectMediaModalOpen] = useState(false)
  const [isAddQuoteBlockModalOpen, setIsAddQuoteBlockModalOpen] = useState(false)

  const [linkAddress, setLinkAddress] = useState('')

  const [mediaFileType, setMediaFileType] = useState<MediaType>(MediaType.IMAGE)
  const [acceptedFiles, setAcceptedFiles] = useState('')

  const handleOpenAddMediaModal = useCallback(() => {
    setIsAddMediaModalOpen(true)
  }, [])

  const handleOpenSelectMediaModal = useCallback(() => {
    setIsSelectMediaModalOpen(true)
  }, [])

  const handleOpenAddLinkModal = useCallback(() => {
    setIsAddLinkModalOpen(true)
  }, [])

  const handleOpenAddQuoteBlockModal = useCallback(() => {
    setIsAddQuoteBlockModalOpen(true)
  }, [])

  const handleAddLink = useCallback((url: string) => {
    setLinkAddress(url)
  }, [])

  const handleAddMedia = useCallback(
    ({ url, videoDuration, fileName, fileSize, mimeType, thumbnail }: MediaInfo) => {
      switch (mediaFileType) {
        case MediaType.IMAGE || MediaType.THUMBNAIL_VIDEO:
          return editor?.chain().focus().setImage({ src: url }).run()
        case MediaType.VIDEO:
          return editor
            ?.chain()
            .focus()
            .setVideo({
              src: url,
              controls: true,
              poster: thumbnail || '',
              duration: videoDuration || 0,
              HTMLAttributes: {}
            })
            .run()
        default:
          return editor
            ?.chain()
            .focus()
            .setFileDownload({
              url,
              HTMLAttributes: {},
              fileName: fileName || '',
              fileSize: fileSize || 0,
              componentSize: 'small',
              mimeType: mimeType || ''
            })
            .run()
      }
    },
    [editor, mediaFileType]
  )

  const handleAddMediaFromLib = useCallback(
    ({ url, type, videoDuration, fileName, fileSize, mimeType, thumbnail }: MediaInfo) => {
      switch (type) {
        case MediaType.IMAGE || MediaType.THUMBNAIL_VIDEO:
          return editor?.chain().focus().setImage({ src: url }).run()
        case MediaType.VIDEO:
          return editor
            ?.chain()
            .focus()
            .setVideo({
              src: url,
              controls: true,
              poster: thumbnail || '',
              duration: videoDuration || 0,
              HTMLAttributes: {}
            })
            .run()
        default:
          return editor
            ?.chain()
            .focus()
            .setFileDownload({
              url,
              HTMLAttributes: {},
              fileName: fileName || '',
              fileSize: fileSize || 0,
              componentSize: 'small',
              mimeType: mimeType || ''
            })
            .run()
      }
    },
    [editor, mediaFileType]
  )

  const handleAddBlockQuote = useCallback(({ quote, author }: QuoteBlockInfo) => {
    if (quote && author) {
      if (!quote.startsWith(`"`) && !quote.startsWith(`'`)) {
        editor
          .chain()
          .focus()
          .setQuote({ quote: `"${quote}"`, author, isPreview: false, HTMLAttributes: {} })
          .run()
      } else {
        editor.chain().focus().setQuote({ quote, author, isPreview: false, HTMLAttributes: {} }).run()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!editor) {
    return <div />
  }

  return (
    <>
      <AddMediaModal
        isOpen={isAddMediaModalOpen}
        onRequestClose={() => setIsAddMediaModalOpen(false)}
        fileType={mediaFileType}
        acceptedFiles={acceptedFiles}
        onAddMedia={handleAddMedia}
        articleId={articleId}
      />
      <SelectMediaModal
        isOpen={isSelectMediaModalOpen}
        onRequestClose={() => setIsSelectMediaModalOpen(false)}
        onAddMedia={handleAddMediaFromLib}
        articleId={articleId}
      />
      <AddLinkModal
        isOpen={isAddLinkModalOpen}
        onRequestClose={() => setIsAddLinkModalOpen(false)}
        onAddLink={handleAddLink}
      />
      <AddQuoteBlockModal
        isOpen={isAddQuoteBlockModalOpen}
        onRequestClose={() => setIsAddQuoteBlockModalOpen(false)}
        onAddBlockQuote={handleAddBlockQuote}
      />

      <FunctionsContainer>
        <MenuItem
          iconName="arrow_undo"
          onClick={() => editor?.chain().focus().undo().run()}
          title="Desfazer"
          shortcut="Ctrl + Z"
          isDisabled={isDisabled}
        />
        <MenuItem
          iconName="arrow_redo"
          onClick={() => editor?.chain().focus().redo().run()}
          title="Refazer"
          shortcut="Ctrl + Shift + Z"
          isDisabled={isDisabled}
        />

        <MenusContainer>
          <DropdownMenu
            label="Texto"
            tooltipTitle="Transforme em..."
            tooltipDescription="Texto, H1, H2 etc."
            groups={[
              {
                label: 'Texto',
                options: [
                  {
                    value: 'text',
                    label: 'Texto',
                    description: 'Escreva um parágrafo, um texto...',
                    action: () => editor.chain().focus().setParagraph().run()
                  },
                  {
                    value: 'h1',
                    label: 'H1',
                    description: 'Usado para títulos',
                    action: () => editor.chain().focus().toggleHeading({ level: 1 }).run()
                  },
                  {
                    value: 'h2',
                    label: 'H2',
                    description: 'Usado para subtítulos',
                    action: () => editor.chain().focus().toggleHeading({ level: 2 }).run()
                  },
                  {
                    value: 'h3',
                    label: 'H3',
                    description: 'Usado para textos de apoio',
                    action: () => editor.chain().focus().toggleHeading({ level: 3 }).run()
                  },
                  {
                    value: 'code',
                    label: 'Bloco de Código',
                    description: 'Usado para adicionar blocos de código',
                    action: () => editor.chain().focus().toggleCodeBlock().run()
                  }
                ]
              }
            ]}
            isDisabled={isDisabled}
          />

          <DropdownMenu
            label="Mídias"
            tooltipTitle="Adicione mídias"
            width="4rem"
            groups={[
              {
                label: 'Mídias',
                options: [
                  {
                    value: 'library',
                    label: 'Biblioteca',
                    description: 'Escolha sua mídia',
                    action: () => {
                      handleOpenSelectMediaModal()
                      return true
                    }
                  },
                  {
                    value: 'image',
                    label: 'Imagem',
                    description: 'Faça upload',
                    action: () => {
                      setMediaFileType(MediaType.IMAGE)
                      setAcceptedFiles('image/*')
                      handleOpenAddMediaModal()
                      return true
                    }
                  },
                  {
                    value: 'video',
                    label: 'Vídeo',
                    description: 'Faça upload',
                    action: () => {
                      setMediaFileType(MediaType.VIDEO)
                      setAcceptedFiles('video/*')
                      handleOpenAddMediaModal()
                      return true
                    }
                  },
                  {
                    value: 'youtube',
                    label: 'Vídeo do Youtube',
                    description: 'Adicione um vídeo do youtube',
                    action: () => {
                      const url = prompt('Adicione o link do vídeo')
                      if (url) {
                        editor?.commands.setYoutubeVideo({
                          src: url || '',
                          width: 1280,
                          height: 720
                        })
                      }
                      return true
                    }
                  },
                  {
                    value: 'downloads',
                    label: 'Downloads',
                    description: 'Faça upload',
                    action: () => {
                      setMediaFileType(MediaType.DOWNLOAD)
                      setAcceptedFiles(
                        [
                          'application/vnd.ms-powerpoint',
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                          'application/vnd.google-apps.presentation',
                          'application/vnd.oasis.opendocument.presentation',
                          'application/msword',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          'application/vnd.google-apps.document',
                          'application/vnd.oasis.opendocument.text',
                          'application/vnd.ms-excel',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'application/vnd.google-apps.spreadsheet',
                          'application/vnd.oasis.opendocument.spreadsheet',
                          'application/pdf',
                          'application/json',
                          'text/xml',
                          'text/csv',
                          'text/tab-separated-values',
                          'text/tsv',
                          '.ipynb',
                          'image/jpeg',
                          'image/bmp',
                          'image/x-ms-bmp',
                          'image/png',
                          'text/markdown',
                          '.tsv'
                        ].join(',')
                      )
                      handleOpenAddMediaModal()
                      return true
                    }
                  }
                ]
              }
            ]}
            isDisabled={isDisabled}
          />

          <DropdownMenu
            label="Componentes"
            tooltipTitle="Adicione componentes"
            width="6.5rem"
            groups={[
              {
                label: 'Componentes',
                options: [
                  {
                    value: 'page-break',
                    label: 'Quebra de página',
                    description: 'Insira uma divisão na página',
                    action: () => editor.chain().focus().addPageBreak().run()
                  },
                  {
                    value: 'prompt-box',
                    label: 'Prompt Box',
                    description: 'Insira um componente para ser enviado para a IA',
                    action: () =>
                      editor
                        .chain()
                        .focus()
                        .addPromptBox({
                          prompt: 'Insira sua pergunta aqui [digite sua pergunta]'
                        })
                        .run()
                  },
                  {
                    value: 'expert-tip-box',
                    label: 'Dica do Expert',
                    description: 'Insira uma dica do Expert',
                    action: () =>
                      editor
                        .chain()
                        .focus()
                        .addExpertTip({
                          message: 'A dica do expert vai aqui',
                          expertPhoto: '',
                          fileName: ''
                        })
                        .run()
                  },
                  {
                    value: 'quote',
                    label: 'Citação',
                    description: 'Insira uma citação na página',
                    action: () => {
                      handleOpenAddQuoteBlockModal()
                      return true
                    }
                  },
                  {
                    value: 'ordered-list',
                    label: 'Lista ordenada de cards coloridos',
                    description: 'Insira cards de fundo colorido na página',
                    action: () => editor.chain().focus().toggleColouredCardList().run()
                  }
                ]
              }
            ]}
            isDisabled={isDisabled}
          />
        </MenusContainer>

        <MenuItem
          iconName="bold"
          isActive={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
          title="Negrito"
          shortcut="Ctrl + B"
          isDisabled={isDisabled}
        />
        <MenuItem
          iconName="italic"
          isActive={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          title="Itálico"
          shortcut="Ctrl + I"
          isDisabled={isDisabled}
        />
        <MenuItem
          iconName="underline"
          isActive={editor.isActive('underline')}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          title="Sublinhado"
          shortcut="Ctrl + U"
          isDisabled={isDisabled}
        />

        <MenuItem
          iconName="attachment_link"
          onClick={() => {
            handleOpenAddLinkModal()
            if (linkAddress) {
              editor.chain().setLink({ href: linkAddress, target: '_blank' }).run()
            }
            setLinkAddress('')
            return true
          }}
          title="Link"
          shortcut="Ctrl + K"
          isDisabled={isDisabled}
        />
      </FunctionsContainer>
    </>
  )
}
