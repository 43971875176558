import React, { FormEvent, useRef, useState } from 'react'
import { Button } from '../../../../core/components/Button'

interface ExpertTipProps {
  message: string
  expertPhoto: string
  updateAttributes: (attrs: { message?: string; expertPhoto?: string }) => void
}

export default function ExpertTip({ message, expertPhoto, updateAttributes }: ExpertTipProps) {
  const [modifiedMessage, setModifiedMessage] = useState(message)
  const [inputExpert, setInputExpert] = useState(false)
  const [modifiedExpertPhoto, setModifiedExpertPhoto] = useState(expertPhoto)

  const contentEditableRef = useRef<HTMLDivElement>(null)

  function handleInputChange(e: FormEvent<HTMLDivElement>) {
    setModifiedMessage(e.currentTarget.textContent || '')
  }

  function handleExpertPhotoChange(e: FormEvent<HTMLInputElement>) {
    setModifiedExpertPhoto(e.currentTarget.value || '')
  }

  function handleInputSave() {
    updateAttributes({ message: modifiedMessage || '' })
  }

  function handleExpertPhotoSave() {
    updateAttributes({ expertPhoto: modifiedExpertPhoto || '' })
    setInputExpert(false)
  }

  function selectExpert() {
    setInputExpert(true)
  }

  function removeExpert() {
    updateAttributes({ expertPhoto: '' })
  }

  return (
    <>
      <div className={'my-12 expert-tip-area' + (expertPhoto ? ' place-with-expert' : '')}>
        <div>
          <span>DICA DE EXPERT:</span>
          <div
            contentEditable
            onInput={handleInputChange}
            onBlur={handleInputSave}
            ref={contentEditableRef}
            suppressContentEditableWarning={true}
            className="border p-2"
          >
            {message}
          </div>
        </div>
      </div>
      {expertPhoto && (
        <>
          <div className="arrow-right" />
          <img className="expert-image" src={expertPhoto} alt={expertPhoto} />
        </>
      )}
      <div className="flex gap-2">
        {!inputExpert && (
          <>
            <Button onClick={() => selectExpert()}>Inserir Foto do Expert</Button>
            {expertPhoto && <Button onClick={() => removeExpert()}>Remover Foto do Expert</Button> }
          </>
        )}

        {inputExpert && (
          <>
            <input onChange={handleExpertPhotoChange} />
            <Button onClick={() => handleExpertPhotoSave()}>Salvar Foto</Button>
          </>
        )}
      </div>
    </>
  )
}
