import React from 'react'

import { NodeViewProps } from '@tiptap/react'
import { NodeContainer } from './styles'
import ExpertTip from '../../ExpertTip'

export const ExpertTipComponent = ({ node, updateAttributes }: NodeViewProps) => {
  return (
    <NodeContainer>
      <ExpertTip
        message={node.attrs.message}
        expertPhoto={node.attrs.expertPhoto}
        updateAttributes={updateAttributes} />
    </NodeContainer>
  )
}
